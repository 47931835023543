@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  
}

* {
  padding: 0px;
  margin: 0;
  font-family: "Poppins";
}
html {
  scroll-behavior: smooth;
}
.container-for-body {
  overflow-x: clip;
}
.last {
  text-align: center;
  cursor: pointer;
}
.carousel__container {
  
  height: auto;
}
.carousel .slider-wrapper.axis-horizontal .slider {
  height: 302px;
}
.carousel .control-dots .dot {
  width: 12px !important;
  height: 12px !important;
  background: white !important;
  opacity: 1 !important;
  box-shadow: 0px 0px !important;
  border: 3px solid #2f0842;
}
.carousel .control-dots .dot.selected {
  width: 12px !important;
  height: 12px !important;
  background: #2f0842 !important;
  opacity: 1 !important;
  box-shadow: 0px 0px !important;
}
.carousel .control-dots {
  padding: 25px 0px 10px !important;
}
.circle {
  width: 12px;
  height: 12px;
  border: 3px solid #2f0842;
  border-radius: 41px;
}
.for-background {
  background: radial-gradient(200.06% 102.25% at 50% 102.25%, #502f58 0%, #2f0842 95.92%);
}
.header-class {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  margin: auto;
  padding-top: 44px;
}
.logo {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: text;
          align-content: text;
  -webkit-align-items: center;
          align-items: center;
}

.logo .menuly{
   margin: 0 0 0 18px;
}

.menuly-logo {
  height: 49.486725px;
  width: 46.946903px;
}

.logo h3 {
  font-size: 23px;
  font-weight: 500;
  color: white;
}
.button-early {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.button-early button {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  background: #ee4e4e;
  border: none;
  color: white;
  border-radius: 30px;
  padding: 12px 16px;
  cursor: pointer;
}
.phones-aimation {
  margin-top: 8%;
  color: white;
  padding-bottom: 20%;
}
.card {
  display: none;
  position: relative;
  width: 610px;
  height: 690px;
  background: grey;
  /* border-radius: 5px; */
  /* box-shadow: 0px 10px 30px -5px rgb(0 0 0 / 30%); */
  /* transition: box-shadow 0.5s, opacity 0.5s; */
  will-change: transform;
  /* border: 10px solid white; */
  /* cursor: grab; */
  /* overflow: hidden; */
  /* touch-action: none; */
  background: url("/static/media/Group 236(1).f0f7f782.png");
  background-repeat: no-repeat;
}
.card > div > * {
  height: 100%;
  background-size: cover;
  background-position: center center;
  margin: 0vw 0;
}
.image {
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex;
}
.for-phones {
  width: 350px;
  margin-top: 10px;
  margin: auto;
}
.for-phones img {
  width: 342px;
}
.meal-planning h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 55px;
  margin-top: 27px;
}
.meal-planning p {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-top: 7px;
  margin: 10px 30px 0px 19px;
}
.meal-planning h6 {
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  margin-top: 19px;
  margin-bottom: 19px;
}
.email1 {
  display: -webkit-flex;
  display: flex;
  padding: 0 12px;
  -webkit-justify-content: center;
          justify-content: center;
}
.email__error {
  border: 3px solid #ee4e4e !important;
  background: white !important;
  color: #ee4e4e;
}
.hide {
  display: none;
}
.checkmark {
  border-radius: 50%;
  width: 60px !important;
  margin-top: 22px;
  height: 60px !important;
}
.regestering {
  margin-top: 12px;
  font-weight: 500;
  font-size: 19px;
}
.email1 input[type="text"] {
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
  border: 0px solid white;
  padding: 11px 23px;
  width: 44%;
  font-size: 14px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;

  /* margin-left: 0px; */
  outline: none;
}
input::-webkit-input-placeholder {
  color: grey;
}
input:-ms-input-placeholder {
  color: grey;
}
input::placeholder {
  color: grey;
}
input:focus::-webkit-input-placeholder {
  color: rgb(182, 174, 174);
}
input:focus:-ms-input-placeholder {
  color: rgb(182, 174, 174);
}
input:focus::placeholder {
  color: rgb(182, 174, 174);
}
.email1 button {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border: none;
  padding: 0px 30px;
  font-size: 14px;
  font-weight: 600;
  background: #35c680;
  color: white;
  cursor: pointer;
}
.padding-first {
  margin-bottom: 9%;
  text-align: center;
}
.portion-div {
  overflow-x: clip;
  /* margin: 0px 16px; */
  padding-top: 70px;
  text-align: center;
}

.video{
  width: 100%;
  height: 100%;
}
.discover {
  margin-top: 26px;
  text-align: center;
  margin-bottom: 0px;
}
.discover h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: #2f0842;
}
.discover p {
  font-size: 16px;
  font-weight: 400;
  color: #414141;
  margin-top: 12px;
  line-height: 28px;
}
.chicken {
  box-shadow: none;
  background: white;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 92px;
}
.chicken img {
  box-shadow: 0px 0px 19px 0px #6f6f6f1a;
  width: 277px;
  border-radius: 20px;
  /* margin-top: 80px; */
  margin-bottom: 0px;
}
.frame-div {
  text-align: center;
}
.frame-div video{
  margin-top: 70px;
}
.frame-div2 {
  text-align: center;
}
.frame-div2 video{
  margin-top: 70px;
}
.meals {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  /* margin: 34px 0; */
  padding: 0 16px;
  text-align: center;
  margin-top: 26px;
}
.meals h1 {
  font-size: 20px;
  font-weight: 600;
  color: #2f0842;
  line-height: 30px;
}
.meals p {
  font-size: 16px;
  font-weight: 400;
  color: #414141;
  margin-top: 20px;
  line-height: 28px;
}
.frame-div {
  /* margin-top: 87px; */
  overflow-x: clip;
}
.frame-div2 {
  /* margin-top: 87px; */
  overflow-x: clip;
}
.frame-div img {
  box-shadow: 0px 0px 16px 0px #6f6f6f1a;
  border-radius: 20px;
  width: 277px;
  margin-top: 90px;
}
.frame-div2 img {
  box-shadow: 0px 0px 16px 0px #6f6f6f1a;
  border-radius: 20px;
  width: 277px;
  margin-top: 90px;
}
.contain {
  text-align: center;
  width: 86%;
  margin: auto;
  margin-top: 90px;
}
.contain h1 {
  font-size: 18px;
  font-weight: 600;
  color: #2f0842;
}
.contain p {
  font-size: 12px;
  font-weight: 400;
  color: #9566a0;
  margin-top: 17px;
  line-height: 18px;
}
.ellipses {
  width: 105px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  margin: auto;
  margin-top: 32px;
  margin-bottom: 150px;
}
.last-div {
  background: radial-gradient(151.95% 77.66% at 49.87% 77.66%, #430d51 0%, #2f0842 100%);
  text-align: center;
  padding-top: 71px;
  color: white;
  padding-bottom: 80px;
}
.last-div h1 {
  font-size: 18px;
  font-weight: 600;
  color: white;
}
.last-div p {
  font-size: 16px;
  font-weight: 400;
  color: white;
  margin-top: 9px;
  margin-bottom: 25px;
}
.email {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  /* padding:0px 24px; */
}
.email input[type="text"] {
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border: 0px solid white;
  padding: 11px 23px;
  width: 43%;
  font-size: 14px;
  outline: none;
}
.email button {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border: none;
  padding: 0px 30px;
  font-size: 14px;
  font-weight: 600;
  background: #35c680;
  color: white;
  cursor: pointer;
}
.big-logo {
  width: 86px;
  margin-top: 60px;
}
.last-div h2 {
  font-weight: 500;
  font-size: 26px;
  margin-top: 13px;
}
.last-div h5 {
  font-size: 10px;
  font-weight: 300;
  color: #a19393;
  margin-top: 26px;
}
.for-images {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}
.menuly1 {
  margin-top: 13px;
}
/* @media screen and (min-width: 320px) {
  .chicken img{
    width: 277px;
  }
  .frame-div{
    width: 277px;
  }
  .frame-div2{
    width: 277px;
  }
}   */

@media screen and (min-width: 354px) {
  .for-phones {
    width: 303px;
  }
  .for-phones img {
    width: 306px;
  }
  .email1 {
    padding: 0px 0px;
  }
  .email {
    padding: 0px 0px;
  }
  .menuly {
    width: 90px;
  }
  .chicken img {
    width: 302px;
  }
  .frame-div img {
    width: 302px;
  }
  .frame-div2 img {
    width: 302px;
  }
  .meal-planning h2 {
    font-size: 22px;
  }
  .logo h3 {
    font-size: 21px;
  }
  .button-early button {
    font-size: 10px;
    padding: 8px 16px;
    background-color: #35c680;
  }
  .checkmark {
    width: 46px !important;
    height: 46px !important;
  }
}

@media screen and (min-width: 357px) {
  .checkmark {
    border-radius: 50%;
    width: 62px !important;
    height: 62px !important;
    margin-top: 49px;
  }
  .regestering {
    font-size: 22px;
  }
  .carousel .slider-wrapper.axis-horizontal .slider {
    height: 281px;
  }
  .frame-div {
    padding: 0px 16px;
  }
  .frame-div2 {
    padding: 0px 16px;
  }
  .portion-div{
    padding: 75px 16px 0 16px;
  }
  .meals {
    padding: 0px;
  }
  .meals h1 {
    font-size: 21px;
  }
  .discover h3 {
    font-size: 21px;
  }
  /* .portion-div {
    padding:0px 16px;
  } */
}
@media screen and (min-width: 411px) {
  .email1 input[type="text"] {
    width: 52%;
  }
  .chicken img {
    width: 342px;
  }
  .frame-div img {
    width: 342px;
  }
  .frame-div2 img {
    width: 342px;
  }
}
@media screen and (min-width: 470px) {
  .email1 input[type="text"] {
    width: 49%;
  }
}
@media screen and (min-width: 562px) {
  .email1 input[type="text"] {
    width: 37%;
  }
  .checkmark {
    width: 78px !important;
    height: 78px !important;
  }
}
@media screen and (min-width: 600px) {
  .checkmark {
    border-radius: 50%;
    width: 77px !important;
    height: 77px !important;
    margin-top: 49px;
  }
  .regestering {
    font-size: 28px;
  }
  .meal-planning h6 {
    font-size: 23px;
  }
  .meal-planning p {
    font-size: 22px;
  }
  .meals p {
    font-size: 22px;
  }
  .meals h1 {
    font-size: 32px;
  }
  .discover h3 {
    font-size: 32px;
  }
  .discover p {
    font-size: 22px;
    line-height: 37px;
  }
  .contain h1 {
    font-size: 30px;
  }
  .contain p {
    font-size: 18px;
  }
  .button-early button {
    padding: 17px 45px;
    font-size: 15px;
  }
  .for-phones {
    width: 483px;
  }
  .for-phones img {
    width: 474px;
  }
  .menuly {
    width: 115px;
  }
  .menuly-logo {
    width: 61px;
    height: 65px;
  }
  .logo h3 {
    display: block;
    font-size: 27px;
    font-weight: 500;
    color: white;
  }
  .chicken {
    margin: 0px 70px;
  }
  .chicken img {
    width: 427px;
  }
  .frame-div img {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 427px;
  }
  .frame-div2 img {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 427px;
  }
  .carousel .slider-wrapper.axis-horizontal .slider {
    height: 343px;
  }
  .last-div h5 {
    font-size: 11px;
  }
  .menuly1 {
    margin-top: 17px;
  }
}
@media screen and (min-width: 885px) {
  .chicken img {
    width: 484px;
  }
  .frame-div img {
    width: 484px;
  }
  .frame-div2 img {
    width: 484px;
  }
  .for-phones {
    width: 600px;
  }
  .for-phones img {
    width: 604px;
  }
  .carousel .slider-wrapper.axis-horizontal .slider {
    height: 316px;
  }
  .email input[type="text"] {
    width: 33%;
  }
}
@media screen and (min-width: 946px) {
  .chicken img {
    width: 644px;
  }
  .frame-div img {
    width: 644px;
  }
  .frame-div2 img {
    margin-right: 12px;
    width: 644px;
  }
  .for-phones {
    width: 642px;
  }

  .for-phones img {
    width: 654px;
  }
  .checkmark {
    border-radius: 50%;
    width: 90px !important;
    height: 90px !important;
    margin-top: 49px;
  }
}
@media screen and (min-width: 1123px) {
  .email1 input[type="text"] {
    width: 25%;
  }
  .carousel .slider-wrapper.axis-horizontal .slider {
    height: 359px;
  }
}
@media screen and (min-width: 1024px) {
  .carousel .slider-wrapper.axis-horizontal .slider {
    height: 384px;
  }
.discover,
.meals {
  width:47%;
}
.video {
  width: 47%;
}
  .carousel .control-dots .dot {
    width: 12px !important;
    height: 12px !important;
    background: white !important;
    opacity: 1 !important;
    box-shadow: 0px 0px !important;
    border: 3px solid #2f0842;
  }
  .carousel .control-dots .dot.selected {
    width: 12px !important;
    height: 12px !important;
    background: #2f0842 !important;
    opacity: 1 !important;
    box-shadow: 0px 0px !important;
  }
  .email1 {
    -webkit-justify-content: start;
            justify-content: start;
    padding-left: 24px;
  }
  .card {
    margin-right: 40px;
    display: block;
    position: relative;
    width: 610px;
    height: 690px;
    background: grey;
    will-change: transform;
    cursor: -webkit-grab;
    cursor: grab;
    background-repeat: no-repeat;
  }
  .card > div {
    will-change: transform;
    height: 100%;
    margin: 0vw 0;
  }
  .card > div > * {
    height: 100%;
    background-size: cover;
    background-position: center center;
    margin: 0vw 0;
  }
  .checkmark {
    margin-top: 26px;
  }

  .chicken img {
    /* margin-top: -50px; */
    width: 484px;
  }
  .frame-div img {
    width: 484px;
    margin-left: 35px;
  }
  .frame-div2 img {
    width: 484px;
    margin-top: 70px;
    margin-right: 12px;
  }
  
  .for-background {
    background: radial-gradient(200.06% 102.25% at 50% 102.25%, #502f58 0%, #2f0842 95.92%);
  }
  .header-class {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: auto;
    padding-top: 44px;
  }
  .menuly-logo {
    height: 65.48672485351562px;
    width: 61.946903228759766px;
  }
  .logo h3 {
    font-size: 27px;
    font-weight: 500;
    color: white;
  }
  .button-early {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }
  .button-early button {
    font-size: 15px;
    font-weight: 600;
    line-height: 16px;
    background: #35c680;
    border: none;
    color: white;
    border-radius: 30px;
    padding: 17px 45px;
  }
  .phones-aimation {
    display: -webkit-flex;
    display: flex;
    margin-top: 8%;
    color: white;
    padding-bottom: 15%;
  }
  .for-phones {
    width: 100%;
  }
  .for-phones img {
    width: 464px;
  }
  .container {
    margin: auto;
    overflow-x: clip;
    width: 982px;
  }
  .container2 {
    width: 1261px;
    margin: auto;
  }
  .meal-planning {
    margin-left: 80px;
    text-align: left;
    padding: 0px;
  }
  .meal-planning h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 55px;
    margin-top: 0px;
  }
  .meal-planning p {
    font-size: 22px;
    font-weight: 400;
    line-height: 38px;
    margin-top: 29px;
    margin-left: 0px;
    margin: 0px;
    margin-top: 29px;
  }
  .meal-planning h6 {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    margin-top: 38px;
    margin-bottom: 6px;
  }
  .checkmark {
    width: 76px !important;
    height: 76px !important;
  }
  .regestering {
    font-size: 25px;
  }
  .show {
    /* margin-right: 53px; */
  }
  .email1 input[type="text"] {
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
    border: 3px solid white;
    padding: 15px 31px;
    width: 62%;
    font-size: 15px;
    outline: none;
  }
  .email1 button {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    border: none;
    /* padding: 14px 34px; */
    font-size: 15px;
    font-weight: 600;
    background: #35c680;
    color: white;
    cursor: pointer;
  }
  .email1 {
    text-align: left;
  }
  .padding-first {
    /* display: flex; */
    -webkit-transform: translateY(70px);
            transform: translateY(70px);
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    margin-bottom: 9%;
  }
  .show {
    /* margin-top: 16px; */
    margin-left: 70px;
  }
  .portion-div {
    display: -webkit-flex;
    display: flex;
    margin: 0;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-justify-content: space-between;
            justify-content: space-between;

  }
  .discover {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: left;
    margin-top: 0px;
    margin-left: 18px;
  }
  .discover h3 {
    font-size: 25px;
    font-weight: 600;
    line-height: 42px;
    color: #2f0842;
  }
  .discover p {
    font-size: 22px;
    font-weight: 400;
    color: #414141;
    margin-top: 24px;
  }
  .chicken {
    text-align: center;
    border-radius: 20px;
    background: white;
    margin: 0px 15px;
    margin-top: 88px;
  }
  .frame-div {
    display: -webkit-flex;
    display: flex;
  -webkit-justify-content:space-between;
          justify-content:space-between;

  }
  .frame-div2 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
  -webkit-justify-content:space-between;
          justify-content:space-between;
    margin: 0;
  }
  .meals {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: left;
    overflow-x: clip;
    margin-left: 0px;
  }
  .meals h1 {
    font-size: 25px;
    font-weight: 600;
    color: #2f0842;
    line-height: 42px;
  }
  .meals p {
    font-size: 22px;
    font-weight: 400;
    color: #414141;
    margin-top: 19px;
    line-height: 38px;
  }
  .email1 {
    text-align: left;
    padding: 0px;
    padding-left: 0px;
    margin-top: 26px;
  }
  .frame-div {
    grid-gap: 106px;
    gap: 106px;
    margin: 0px;
  }
  .frame-div img {
    margin-left: 17px;
    margin-right: 17px;
    margin-top: 70px;
    border-radius: 20px;
    box-shadow: 0px 0px 19px 0px #6f6f6f1a;
  }
  .contain {
    text-align: center;
    width: 86%;
    margin: auto;
    margin-top: 60px;
  }
  .contain h1 {
    font-size: 30px;
    font-weight: 600;
    color: #2f0842;
  }
  .contain p {
    font-size: 18px;
    font-weight: 400;
    color: #9566a0;
    margin-top: 29px;
  }
  .ellipses {
    width: 105px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    margin: auto;
    margin-top: 32px;
    margin-bottom: 150px;
  }
  .last-div {
    background: radial-gradient(151.95% 77.66% at 49.87% 77.66%, #430d51 0%, #2f0842 100%);
    text-align: center;
    padding-top: 110px;
    color: white;
    padding-bottom: 80px;
  }
  .last-div h1 {
    font-size: 26px;
    font-weight: 600;
    color: white;
  }
  .last-div p {
    font-size: 20px;
    font-weight: 400;
    color: white;
    margin-top: 3px;
    margin-bottom: 24px;
  }
  .email input[type="text"] {
    width: 23%;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border: 0px solid white;
    padding: 15px 31px;
    font-size: 15px;
    outline: none;
  }
  .email button {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    border: none;
    padding: 0px 38px;
    font-size: 15px;
    font-weight: 600;
    background: #35c680;
    color: white;
    cursor: pointer;
  }
  .big-logo {
    width: 86px;
    margin-top: 80px;
  }
  .last-div h2 {
    font-weight: 600;
    font-size: 28px;
    margin-top: 17px;
    letter-spacing: 2px;
  }
  .last-div h5 {
    font-size: 11px;
    font-weight: 300;
    color: #a19393;
    margin-top: 36px;
  }
}
@media screen and (min-width: 1264px) {
  .carousel .slider-wrapper.axis-horizontal .slider {
    height: 381px;
  }

  .carousel .control-dots .dot {
    width: 12px !important;
    height: 12px !important;
    background: white !important;
    opacity: 1 !important;
    box-shadow: 0px 0px !important;
    border: 3px solid #2f0842;
  }
  .carousel .control-dots .dot.selected {
    width: 12px !important;
    height: 12px !important;
    background: #2f0842 !important;
    opacity: 1 !important;
    box-shadow: 0px 0px !important;
  }
  .checkmark {
    border-radius: 50%;
    margin-top: 42px;
    width: 103px !important;
    height: 103px !important;
  }
  .regestering {
    margin-top: 12px;
    font-weight: 500;
    font-size: 30px;
  }
  .card {
    margin-right: 40px;
    display: block;
    position: relative;
    width: 610px;
    height: 690px;
    background: grey;
    will-change: transform;
    cursor: -webkit-grab;
    cursor: grab;
    background-repeat: no-repeat;
  }
  .card > div {
    will-change: transform;
    height: 100%;
    margin: 0vw 0;
  }
  .card > div > * {
    height: 100%;
    background-size: cover;
    background-position: center center;
    margin: 0vw 0;
  }

  .chicken img {
    /* margin-top: -50px; */
    width: 547px;
  }
  .frame-div img {
    width: 547px;
    margin-left: 35px;
  }
  .frame-div2 img {
    width: 547px;
    margin-top: 70px;
    margin-right: 12px;
  }
  .for-background {
    background: radial-gradient(200.06% 102.25% at 50% 102.25%, #502f58 0%, #2f0842 95.92%);
  }
  .header-class {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: auto;
    padding-top: 44px;
  }
  .menuly-logo {
    height: 65.48672485351562px;
    width: 61.946903228759766px;
  }
  .logo h3 {
    font-size: 27px;
    font-weight: 500;
    color: white;
  }
  .button-early {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }
  .button-early button {
    font-size: 15px;
    font-weight: 600;
    line-height: 16px;
    background: #35C680;
    border: none;
    color: white;
    border-radius: 30px;
    padding: 17px 45px;
  }
  .phones-aimation {
    display: -webkit-flex;
    display: flex;
    margin-top: 8%;
    color: white;
    padding-bottom: 10%;
  }
  .for-phones {
    width: 100%;
  }
  .for-phones img {
    width: 587px;
  }
  .container {
    margin: auto;
    /* overflow-x: clip; */
    width: 1212px;
  }
  .container2 {
    width: 1261px;
    margin: auto;
  }
  .meal-planning {
    margin-left: 80px;
    text-align: left;
    padding: 0px;
    margin-top: 48px;
  }
  .meal-planning h2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 55px;
    margin-top: 0px;
  }
  .meal-planning p {
    font-size: 22px;
    font-weight: 400;
    line-height: 38px;
    margin-top: 29px;
    margin-left: 0px;
    margin: 0px;
    margin-top: 29px;
  }
  .meal-planning h6 {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    margin-top: 38px;
    margin-bottom: 6px;
  }
  .email1 input[type="text"] {
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    padding: 13px 31px;
    width: 62%;
    font-size: 15px;
    border: 3px solid white;
    /* margin-left: 24px; */
    outline: none;
  }
  .email1 button {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border: none;
    padding: 0px 39px;
    font-size: 15px;
    font-weight: 600;
    background: #35c680;
    color: white;
    cursor: pointer;
  }
  .email1 {
    text-align: left;
    padding: 0px;
    padding-left: 0px;
    margin-top: 26px;
  }
  .padding-first {
    /* display: flex; */
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    margin-bottom: 9%;
  }
  .portion-div {
    display: -webkit-flex;
    display: flex;
    margin: 0;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    padding-top: 85px;
    /* padding: 75px 15px 0 15px; */
  }
  .discover {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: left;
    margin-top: 0px;
    margin-left: 18px;
  }
  .discover h3 {
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
    color: #2f0842;
  }
  .discover p {
    font-size: 22px;
    font-weight: 400;
    color: #414141;
    margin-top: 24px;
  }
  .chicken {
    text-align: center;
    border-radius: 20px;
    background: white;
    margin: 0px 15px;
    margin-top: 88px;
  }
  .frame-div {
    display: -webkit-flex;
    display: flex;
  }
  .frame-div2 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    margin: 0;
    grid-gap: 106px;
    gap: 106px;
    padding-top:55px;
    padding-bottom:65px ;
  }
  .meals {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: left;
    overflow-x: clip;
    margin-left: 0px;
  }
  .meals h1 {
    font-size: 32px;
    font-weight: 600;
    color: #2f0842;
  }
  .meals p {
    font-size: 22px;
    font-weight: 400;
    color: #414141;
    margin-top: 19px;
  }
  .frame-div {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    grid-gap: 106px;
    gap: 106px;
    margin: 0px;
    margin-top: 55px;
  }
  .frame-div img {
    margin-left: 17px;
    margin-right: 17px;
    margin-top: 70px;
    border-radius: 20px;
    box-shadow: 0px 0px 19px 0px #6f6f6f1a;
  }
  .contain {
    text-align: center;
    width: 86%;
    /* margin: auto;
    margin-top: 81px; */
  }
  .contain h1 {
    font-size: 30px;
    font-weight: 600;
    color: #2f0842;
  }
  .contain p {
    font-size: 18px;
    font-weight: 400;
    color: #9566a0;
    margin-top: 29px;
    margin-bottom: 15px;
  }
  .ellipses {
    width: 105px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    margin: auto;
    margin-top: 32px;
    margin-bottom: 150px;
  }
  .last-div {
    background: radial-gradient(151.95% 77.66% at 49.87% 77.66%, #430d51 0%, #2f0842 100%);
    text-align: center;
    padding-top: 350px;
    color: white;
    padding-bottom: 68px;
    margin-top:-200px;
  }
  .last-div h1 {
    font-size: 26px;
    font-weight: 600;
    color: white;
  }
  .last-div p {
    font-size: 20px;
    font-weight: 400;
    color: white;
    margin-top: 3px;
    margin-bottom: 24px;
  }
  .email input[type="text"] {
    width: 23%;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border: 0px solid white;
    padding: 15px 31px;
    font-size: 15px;
    outline: none;
  }
  .email button {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    border: none;
    padding: 0px 38px;
    font-size: 15px;
    font-weight: 600;
    background: #35c680;
    color: white;
    cursor: pointer;
  }
  .big-logo {
    width: 86px;
    margin-top: 80px;
  }
  .last-div h2 {
    font-weight: 600;
    font-size: 28px;
    margin-top: 17px;
    letter-spacing: 2px;
  }
  .last-div h5 {
    font-size: 11px;
    font-weight: 300;
    color: #a19393;
    margin-top: 36px;
  }
}


.video{
  width: 100%;
  max-width: 547px;
  height: auto;
  box-shadow:0 0 19px  hsl(0deg 0% 44% / 10%);
  background-color: #FFFFFF;
  border-radius: 20px;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide{
  background-color: #FFFFFF;
  position: relative;
}
.carousel .slider-wrapper{
  border-radius: 20px;
}
.carousel-root{
  margin-right:16px;
  margin-left:16px !important;
  box-shadow:0 0 19px 5px hsl(0deg 0% 44% / 10%);
  border-radius: 20px;
}
/* .control-dots button{
  height:350px;
} */
.icon{
  position: relative;
  text-align: center;
  top: 30px;
  z-index: 11111;
}
.icon img{
  width: 50px;
}
@media screen and (min-width: 1024px){
  .carousel .slider-wrapper.axis-horizontal .slider{
    height: 315px;
  }
}
@media screen and (min-width: 1250px){
  .carousel .slider-wrapper.axis-horizontal .slider{
    height: 315px;
  }
}
@media screen and (min-width: 900px){
  .carousel .slider-wrapper.axis-horizontal .slider{
    height: 315px;
  }
}
@media screen and (min-width: 357px){
  .carousel .slider-wrapper.axis-horizontal .slider{
    height: 275px;
  }
  .contain h1{
    margin-top:-10px;
  }
}



 /* .carousel .slider-wrapper.axis-horizontal .slider{
  height: 355px;
}  */



/* @media screen and (min-width: 600px){
  .carousel .slider-wrapper.axis-horizontal .slider{
    height: 400px;
  }
}
/* /*   */


/* .contain{
  background-color:red;
} */

/* .shopping-list{
  margin-top: 70px;
} */
/* .carousel .slider-wrapper{
  padding:0 15px 0 15px;
} */

